import React from 'react';
import scrollToTop from '../helpers/scrollToTop';
import DialButton from '../components/DialButton';
import { motion } from 'framer-motion';
import contact from '../images/contact.jpg'

function Contact() {
  const phoneNumber = '088 422 7335';

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  const iconHover = {
    scale: 1.2,
    rotate: 10,
    transition: { duration: 0.3, ease: 'easeInOut' } // Smooth transition
  };

  return (
    <motion.div
      className="min-h-screen"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className='relative'>
        <img
          className='brightness-75 grayscale object-cover h-[40vh] object-left-bottom w-full'
          src={contact}
          alt='Contact'
        />
        <h2 className="absolute h-full top-0 flex items-center left-1/2 -translate-x-1/2 text-center py-4 text-6xl text-red-800">
          Контакти
        </h2>
      </div>
      <div className="max-w-xl mx-auto p-6 text-red-800">
        <p className='text-3xl text-center'>
          Не се колебайте да се свържете – ще се радваме да поговорим!
        </p>
        <p className='text-center text-4xl py-4 font-bold'>{phoneNumber}</p>

        <div className="flex justify-center">
          <DialButton phoneNumber={phoneNumber} />
        </div>

       
        <div className='flex flex-col items-center justify-center mt-6'>
          <p className='text-2xl mb-4'>Последвайте ни в социалните мрежи:</p>
          <ul className='flex gap-4'>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100067700791666"
                target="_blank"
                rel="noopener noreferrer"
              >
                <motion.img
                  className='w-10'
                  src='https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-512.png'
                  alt='Facebook'
                  whileHover={iconHover}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/berbershop.alexievs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <motion.img
                  className='w-10'
                  src='https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png'
                  alt='Instagram'
                  whileHover={iconHover}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@alexievbarbershop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <motion.img
                  className="w-10"
                  src="https://cdn-icons-png.flaticon.com/512/3046/3046121.png"
                  alt="TikTok"
                  whileHover={iconHover}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
