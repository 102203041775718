function BarberServices() {
    return (
        <section className="max-w-xl">
            <h2 className="text-center text-3xl py-4 text-red-800">Нашите Услуги</h2>
            <ul className="max-w-6xl grid grid-cols-1 grid-rows-1 gap-2 text-center mx-auto">
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Подстрижка</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>25лв</p>
                    </div>
                    <p>Коса - 30 Мин</p>
                </div>
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Брада</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>15лв</p>
                    </div>
                    <p>Брада - 30 Мин</p>
                </div>
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Коса, брада</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>40лв</p>
                    </div>
                    <p>Коса, брада - 1 час</p>
                </div>
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Коса, брада,
                            <br/>почистване на лице</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>60лв</p>
                    </div>
                    <p>1 час и 30 Мин</p>
                </div>
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Коса, брада, боя</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>50лв</p>
                    </div>
                    <p>1 час и 30 Мин</p>
                </div>
                
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Дълбоко почистване на лице</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>20лв</p>
                    </div>
                    <p>30 Мин</p>
                </div>
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Кола маска</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>10лв</p>
                    </div>
                    <p>За резервация по телефон</p>
                </div>
                <div className="text-left">
                    <div className="flex items-end">
                        <h2 className="font-bold min-w-fit">Вежди</h2>
                        <div className="h-px w-full mb-1 bg-neutral-400"></div>
                        <p>10лв</p>
                    </div>
                    <p>За резервация по телефон</p>
                </div>
            </ul>
        </section>
    );
}

export default BarberServices;
