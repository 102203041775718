import React, { useState, useEffect } from 'react';
import scrollToTop from '../helpers/scrollToTop';
import pompadour from '../images/pampadur.jpg';
import taperFade from '../images/taper fade.jpg';
import taperFade2 from '../images/taper fade2.jpg';
import middlePart from '../images/middle part.jpg';
import lowFade from '../images/low fade.jpg';
import frenchCrop from '../images/french crop.jpg';
import beard from '../images/beard.jpg';
import beard2 from '../images/Beard2.jpg';
import midFade from '../images/mid fade.jpg';
import midFade2 from '../images/mid fade2.jpg';
import midFade3 from '../images/mid fade re.jpg';
import midFade4 from '../images/mid fade 4.jpg';
import burstFade from '../images/burst fade.jpg';
import burstFade2 from '../images/burst fade2.jpg';
import buzzCut from '../images/buzz cut.jpg';
import highFade from '../images/high fade.jpg';
import frenchCrop2 from '../images/french crop re.jpg'
import heartArt from '../images/aleks heart art.jpg'
import dropFade from '../images/drop fade.jpg'
import sidePart from '../images/side part re.jpg'
import sidePart2 from '../images/side part re 2.jpg'
import beard3 from '../images/beard3.jpg'

// Example haircut images (replace these URLs with actual URLs of haircut images)
const haircutImages = [
  { id: 1, src: frenchCrop, title: 'French Crop' },
  { id: 2, src: lowFade, title: 'Low Fade' },
  { id: 3, src: taperFade, title: 'Taper Fade' },
  { id: 4, src: pompadour, title: 'Pompadour' },
  { id: 5, src: middlePart, title: 'Middle Part' },
  { id: 6, src: taperFade2, title: 'Taper Fade' },
  { id: 7, src: beard, title: 'Beard' },
  { id: 8, src: beard2, title: 'Beard' },
  { id: 9, src: midFade, title: 'Mid Fade' },
  { id: 10, src: midFade2, title: 'Mid Fade' },
  { id: 11, src: midFade3, title: 'Mid Fade' },
  { id: 12, src: midFade4, title: 'Mid Fade' },
  { id: 13, src: burstFade, title: 'Burst Fade' },
  { id: 14, src: burstFade2, title: 'Burst Fade' },
  { id: 15, src: buzzCut, title: 'Buzz Cut' },
  { id: 16, src: highFade, title: 'High Fade' },
  { id: 17, src: frenchCrop2, title: 'French Crop' },
  { id: 18, src: heartArt, title: 'Heart Art' },
  { id: 20, src: beard3, title: 'Beard' },
  { id: 21, src: dropFade, title: 'Drop Fade' },
  { id: 22, src: sidePart, title: 'Side Part' },
  { id: 23, src: sidePart2, title: 'Side Part' },
];

function HaircutGallery() {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-neutral-900 text-neutral-300">
      <h2 className="text-3xl font-semibold text-center my-8 text-neutral-100">Галерия</h2>
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 p-4 w-full max-w-4xl">
        {haircutImages.map((image) => (
          <div key={image.id} className="bg-neutral-800 p-2 rounded-lg shadow-md">
            <img
              src={image.src}
              alt={image.title}
              className="w-full h-48 object-cover rounded cursor-pointer hover:opacity-80"
              onClick={() => handleImageClick(image)}
            />
            <p className="text-center mt-2 text-neutral-100">{image.title}</p>
          </div>
        ))}
      </div>

      {/* Modal for selected image */}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative p-4 bg-neutral-800 rounded-lg max-w-md w-full">
            <button
              className="absolute top-2 right-2 text-neutral-400 hover:text-neutral-100"
              onClick={closeImageModal}
            >
              ✕
            </button>
            <img
              src={selectedImage.src}
              alt={selectedImage.title}
              className="w-full h-auto rounded"
            />
            <p className="text-center mt-2 text-neutral-100">{selectedImage.title}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default HaircutGallery;
