import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import scrollToTop from '../helpers/scrollToTop';
import bobi from '../images/viber_image_2024-10-27_18-39-32-616.jpg';
import emo from '../images/emo.jpg';
import ekip from '../images/team.jpg';
import { motion } from 'framer-motion';

function Team() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  return (
    <motion.div
      className="min-h-screen"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="relative">
        <img
          className="brightness-75 grayscale object-cover h-[40vh] object-left-bottom w-full"
          src={ekip}
          alt="Team Banner"
        />
        <h2 className="absolute h-full top-0 flex items-center left-1/2 -translate-x-1/2 text-center py-4 text-6xl text-red-800">
          Екип
        </h2>
      </div>

      <section className="flex flex-wrap justify-center gap-8 py-8 bg-red-950 bg-opacity-10 text-neutral-900">
  <div className="relative w-[500px] h-[750px] overflow-hidden">
    <div className="absolute z-10 text-xl top-0 px-2 py-8 flex justify-center items-end gap-2 w-full text-neutral-300">
      <p className="font-bold">Емо</p>
      <p>|</p>
      <p>Master Barber</p>
      <p>|</p>
      <Link className="underline" to="/team/emo">
        Виж Повече
      </Link>
    </div>
    <img
      className="h-full w-full object-cover grayscale"
      src={emo}
      alt="Emo"
    />
  </div>

  <div className="relative w-[500px] h-[750px] overflow-hidden">
    <div className="absolute z-10 text-xl top-0 px-2 py-8 flex justify-center items-end gap-2 w-full text-neutral-300">
      <p className="font-bold">Боян</p>
      <p>|</p>
      <p>Експерт Бръснар</p>
      <p>|</p>
      <Link className="underline" to="/team/bobi">
        Виж Повече
      </Link>
    </div>
    <img
      className="h-full w-full object-cover grayscale"
      src={bobi}
      alt="Bobi"
    />
  </div>
</section>

    </motion.div>
  );
}

export default Team;
