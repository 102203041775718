import React from 'react';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HomeIcon } from '@heroicons/react/solid';

function Footer() {
  const iconHover = {
    scale: 1.2,
    rotate: 10,
    transition: { duration: 0.3, ease: 'easeInOut' }
  };

  return (
    <div className='bg-neutral-950 relative p-8 nav text-neutral-300 flex items-center justify-evenly'>
      <ul className='flex gap-1 flex-col items-center justify-center'>
        <img className='w-40' src={logo} alt="Logo" />
        <Link to='/reservations'>Резервирай Сега</Link>
        <Link to='/contact'>Контакти</Link>
        <Link to='/about'>За нас</Link>
        <Link to='/team'>Екип</Link>
        <Link to='/gallery'>Галерия</Link>
        <Link to='/services'>Нашите Услуги</Link>
      </ul>

      <ul className="flex gap-4 flex-col items-center justify-center">
        <a href="https://www.facebook.com/profile.php?id=100067700791666" target="_blank" rel="noopener noreferrer">
          <motion.img
            className="w-10"
            src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-512.png"
            alt="Facebook"
            whileHover={iconHover}
          />
        </a>
        <a href="https://www.instagram.com/berbershop.alexievs/" target="_blank" rel="noopener noreferrer">
          <motion.img
            className="w-10"
            src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
            alt="Instagram"
            whileHover={iconHover}
          />
        </a>
        <a href="https://www.tiktok.com/@alexievbarbershop" target="_blank" rel="noopener noreferrer">
          <motion.img
            className="w-10"
            src="https://cdn-icons-png.flaticon.com/512/3046/3046121.png"
            alt="TikTok"
            whileHover={iconHover}
          />
        </a>
      </ul>
      <Link to='/login'>
        <motion.div
          className='w-12 h-12 rounded-full bg-neutral-700 flex items-center justify-center absolute bottom-4 right-4 cursor-pointer'
          whileHover={{ scale: 1.1 }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-neutral-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A9 9 0 0112 2c2.053 0 3.942.683 5.45 1.821m0 0a8.977 8.977 0 012.451 6.179M7 11a5 5 0 015-5m0 0a5 5 0 015 5M7 17v5m10-5v5m-5-6v6" />
          </svg>
        </motion.div>
      </Link>
    </div>
  );
}

export default Footer;
