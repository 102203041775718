import React, { useEffect } from 'react';
import BarberShopReservation from '../components/BarberShopReservation';
import scrollToTop from '../helpers/scrollToTop';
import { motion } from 'framer-motion';
import reservation from '../images/reservation.jpg';
import BarberServices from '../components/BarberServices';

function Reservations() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  return (
    <motion.div
      className="min-h-screen"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className='relative'>
        <img
          className='brightness-75 grayscale object-cover h-[40vh] object-left-bottom w-full'
          src={reservation}
          alt='Reservation Banner'
        />
        <h2 className="absolute h-full top-0 flex items-center left-1/2 -translate-x-1/2 text-center py-4 text-6xl text-red-800"
        >
          Резервирай
        </h2>
      </div>
      
      <section className="flex flex-col md:flex-row justify-between max-w-6xl mx-auto py-4">
        {/* Left Side: Services List */}
        <div className="w-full px-4 md:w-2/4 md:pr-4 order-2 md:order-1 mt-4 md:mt-0">
          <BarberServices/>
        </div>

        {/* Right Side: BarberShopReservation Component */}
        <div className="w-full md:w-2/4 md:pl-4 order-1 md:order-2">
          <BarberShopReservation />
        </div>
      </section>
    </motion.div>
  );
}

export default Reservations;
