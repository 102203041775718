import React from 'react';

const DialButton = ({ phoneNumber }) => {
  return (
    <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none' }} className="dial-button">
      <button style={buttonStyle}>Обади Се Сега</button>
    </a>
  );
};

const buttonStyle = {
  fontFamily: '"Raleway", sans-serif',
  textTransform: 'uppercase',
  color: '#fff',
  backgroundColor: '#28a745', 
  backgroundImage: 'linear-gradient(to right, #28a745 0%, #1e7d32 100%)',  // Green gradient
  padding: '14px 34px',
  letterSpacing: '1px',
  fontSize: '15px',
  fontWeight: 500,
  borderRadius: '25px',
  transition: 'all 0.5s linear',
  border: 'none',
  cursor: 'pointer'
};


const styles = `
  @media (min-width: 768px) {
    .dial-button {
      display: none;
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default DialButton;
