import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ServicesSelect({ setSelectedService }) {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch services from the API
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://alexievs-001-site1.ltempurl.com/api/services'); // Update with your API URL
        setServices(response.data); // Assuming the API returns an array of services
        setLoading(false);
      } catch (err) {
        setError('Грешка при зареждането на услугите');
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) return <p>Зареждане на услугите...</p>;
  if (error) return <p>{error}</p>;

  return (
    <select
      defaultValue={services[0]?.id || ''}
      className="border w-full border-red-800 bg-neutral-100 rounded-lg p-2 transition duration-300 hover:shadow-lg focus:shadow-outline"
      onChange={(e) => setSelectedService(e.target.value)}
    >
      {services.map((service) => (
        <option key={service.id} value={service.id}>
          {service.name}
        </option>
      ))}
    </select>
  );
}

export default ServicesSelect;
