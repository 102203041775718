import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
import { useNavigate } from 'react-router-dom';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [failedAttempts, setFailedAttempts] = useState(0);
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    // Send login request to API
    try {
      const response = await axios.post('https://alexievs-001-site1.ltempurl.com/api/Auth/login', { username, password });

      if (response.data === true) {
        Cookies.set('isAuthenticated', true, { expires: 365 });
        setError('');
        setFailedAttempts(0);
        navigate('/'); // Optional: Redirect to a protected route here
      } else {
        // Unsuccessful login
        setFailedAttempts(prev => prev + 1);
        setError('Невалидно име или парола');

        // If failed attempts exceed threshold, show warning or lock out
        if (failedAttempts >= 5) {
          alert('Твърде много неуспешни опити, опитайте по-късно.');
          // Implement a cooldown or lockout mechanism if necessary
        }
      }
    } catch (error) {
     // console.error('Login error:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-neutral-900 text-neutral-300">
      <div className="p-8 bg-neutral-800 rounded-lg shadow-md w-80">
        <h2 className="text-2xl font-semibold text-center mb-6">Вход Админ</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2" htmlFor="username">Име</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 bg-neutral-700 text-neutral-300 rounded focus:outline-none focus:ring-2 focus:ring-neutral-500"
              placeholder="Enter your username"
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2" htmlFor="password">Парола</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-neutral-700 text-neutral-300 rounded focus:outline-none focus:ring-2 focus:ring-neutral-500"
              placeholder="Enter your password"
            />
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full p-2 bg-blue-600 text-neutral-100 font-semibold rounded hover:bg-blue-700 transition duration-200"
          >
            Вход
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
