import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import scrollToTop from '../helpers/scrollToTop'
import emo from '../images/emo.jpg'
import bobi from '../images/viber_image_2024-10-27_18-39-32-616.jpg'
import ekip from '../images/team.jpg';

function TeamMember() {
    const params = useParams()
    const [member, setMember] = useState({})
    const members = {
        emo: {
            name: 'Емо',
            desc: `
            Емо Алексиев винаги е бил човек, воден от страстта си към бръснарството. Още от 8-годишен, той вече знаеше, че иска да бъде бръснар. Първите му клиенти бяха неговите плюшени мечета, но това бързо се промени, когато станал на 13 години. Приятелите му му се довериха да експериментира върху техните прически и така страстта на Емо продължи да расте.

            След малка пауза, на 18 години Емо реши да се захване сериозно с бръснарството. Работеше усилено като куриер, за да събере пари за професионален курс и така попадна при известния Асен Налбантов, който му помогна да усвои тънкостите на професията. С времето Емо стана още по-добър и натрупа опит в различни бръснарници, докато не събра достатъчно кураж и средства да отвори собствено място заедно с брат си - ALEXIEV’S BARBERSHOP.

            Днес, Емо е горд собственик на своето бръснарско студио, където не само подстригва, но и вдъхновява младите да следват мечтите си. Той вярва, че всяка прическа има потенциала да промени не само външния вид на човек, но и неговата увереност. За Емо, ALEXIEV’S BARBERSHOP не е просто работа, а семейство, страст и начин на живот.
          `,
            image: emo,
            subheader: 'Основател и Мастър Бръснар'
        },
        bobi: {
            name: 'Боян',
            desc: `
            Боян Алексиев е братът на Емо и неотменна част от успеха на ALEXIEV’S BARBERSHOP. След като Емо и Боян отвориха студиото, Емо пое инициативата да обучи брат си в тънкостите на бръснарството. След това Боян премина и двуседмичен курс при Налби, за да доразвие своите умения и да стане още по-добър.

            Днес, Боян и Емо работят рамо до рамо в тяхната бръснарница. Боян е известен със своята прецизност и приятелски подход към клиентите, което го прави любим на мнозина. Заедно, братята Алексиеви създават място, където клиентите могат не само да получат страхотна прическа, но и да се почувстват част от едно истинско семейство.
          `,
            image: bobi,
            subheader: 'Мастър Бръснар'
        }
    }
    useEffect(() => {
        scrollToTop()
        if(params.id === 'emo') {
            setMember(members.emo)
        }
        if(params.id === 'bobi') {
            setMember(members.bobi)
        }
        
      },[params.id])
    return (
      <div className='min-h-screen'>
          <div className='relative'>
              <img className='brightness-75 grayscale object-cover h-[40vh] object-left-bottom w-full' src={ekip}></img>
              <h2 className="absolute h-full top-0 flex items-center left-1/2 -translate-x-1/2 text-center py-4 text-6xl text-red-800">Експерт Бръснар</h2>
          </div>
        <section className="py-20 gap-4 flex justify-center relative text-neutral-950">
          <div className="flex flex-col gap-4 px-4">
            <p className="text-4xl">{member.name}</p>
            <p className="italic">{member.subheader}</p>
            <p className="max-w-lg mx-auto text-xl">{member.desc}</p>
          </div>
          <img src={member.image} className="w-1/4 h-fit grayscale object-cover hidden md:block" ></img>
        </section>
      </div>
    )
}

export default TeamMember