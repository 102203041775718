import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Services from './pages/Services';
import Nav from './components/Nav';
import NotFound from './pages/Notfound';
import Contact from './pages/Contact';
import About from './pages/About';
import Team from './pages/Team';
import Reservations from './pages/Reservations';
import Footer from './components/Footer';
import TeamMember from './pages/TeamMember';
import Login from './pages/Login';
import HaircutGallery from './pages/Gallery.js';

const callButtonStyle = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  backgroundColor: '#28a745',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
  zIndex: 1000,
  transition: 'transform 0.2s ease-in-out',
  cursor: 'pointer',
};

const callButtonHoverStyle = {
  transform: 'scale(1.1)',
};

const phoneIcon = (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" height="24"
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="white" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    style={{ pointerEvents: 'none' }} // Ensures hover doesn't get blocked
  >
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 
      19.86 19.86 0 0 1-8.63-3.06 
      19.29 19.29 0 0 1-6-6 
      19.86 19.86 0 0 1-3.05-8.62 
      2 2 0 0 1 2-2.19h3a2 2 0 0 1 2 
      1.72c.13.86.35 1.7.64 2.5a2 
      2 0 0 1-.45 2.11L7.91 9.91a16 
      16 0 0 0 6 6l1.39-1.39a2 2 
      0 0 1 2.11-.45c.8.29 1.64.51 
      2.5.64a2 2 0 0 1 1.72 2z">
    </path>
  </svg>
);

class FloatingCallButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  render() {
    return (
      <a
        href="tel:+359 88 422 7335"
        style={{
          ...callButtonStyle,
          ...(this.state.hover ? callButtonHoverStyle : {}),
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {phoneIcon}
      </a>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Nav/>
    <Routes>
      <Route path='/' Component={App}></Route>
      <Route path='/services' Component={Services}></Route>
      <Route path='/contact' Component={Contact}></Route>
      <Route path='/about' Component={About}></Route>
      <Route path='/team' Component={Team}></Route>
      <Route path='/team/:id' Component={TeamMember}></Route>
      <Route path='/reservations' Component={Reservations}></Route>
      <Route path='/login' Component={Login}></Route>
      <Route path='/gallery' Component={HaircutGallery}></Route>
      <Route path='*' Component={NotFound}></Route>
    </Routes>
    <Footer />

    {/* The updated floating call button */}
    <FloatingCallButton />
  </BrowserRouter>
);

reportWebVitals();
