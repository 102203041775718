import React, { useEffect } from 'react';
import BarberServices from '../components/BarberServices';
import SalonServices from '../components/SalonServices';
import scrollToTop from '../helpers/scrollToTop';
import { motion } from 'framer-motion';
import servicesBackground from '../images/pexels-nickoloui-1319459.jpg'

function Services() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  return (
    <motion.div
      className="min-h-screen"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="relative">
        <img
          className="brightness-75 grayscale object-cover h-[40vh] object-left-bottom w-full"
          src={servicesBackground}
          alt="Services Banner"
        />
        <h2 className="absolute h-full top-0 flex items-center left-1/2 -translate-x-1/2 text-center py-4 text-6xl text-red-800">
          Услуги
        </h2>
      </div>

      <section className="w-xl flex flex-col md:flex-row mx-auto justify-center items-center gap-12 px-4 pb-4">
        <BarberServices />
        {/* <SalonServices /> */}
      </section>
    </motion.div>
  );
}

export default Services;
