import React, { useEffect, useRef } from 'react';
import barbervid from './videos/barbervid.mp4';
import { Link } from 'react-router-dom';

function VideoBanner() {
  const videoRef = useRef(null); // Reference for the video element

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Attempt to play the video programmatically for Safari
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Video is playing');
          })
          .catch((error) => {
            console.error('Autoplay failed:', error);
            video.muted = true; // Force mute and retry playing
            video.play();
          });
      }
    }
  }, []);

  return (
    <div className='relative px-4 h-[75vh] bg-neutral-900 flex flex-col gap-4 justify-center items-center'>
      {/* Text content */}
      <h2 className='z-20 text-neutral-300 text-3xl text-center'>
        Съвършената прическа започва с добрия бръснар
      </h2>
      
      <Link
  to="/reservations"
  className="bg-red-900 nav z-20 text-neutral-300 text-xl px-6 py-3 font-bold block reservation-button rounded-lg shadow-lg hover:shadow-xl hover:bg-red-800 transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-red-500"
>
  РЕЗЕРВИРАЙ СЕГА
</Link>


      {/* Video element */}
      <video
        ref={videoRef} // Attach ref to video
        className='absolute opacity-60 top-0 left-0 w-full h-full object-cover'
        src={barbervid}
        muted
        loop
        autoPlay
        playsInline
        preload='auto'
        disablePictureInPicture
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoBanner;
